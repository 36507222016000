<template>
  <div class="fulls">
    <v-card flat>
      <v-card-title>
        Users
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <users-list :users="usersFiltered" />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import usersList from '../components/users/usersList.vue';

export default {
  components: {
    usersList,
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapGetters(['users']),
    usersPackage() {
      return this.users
        .map((elem) => ({
          id: elem.id,
          email: elem.email,
          name: `${elem.firstname} ${elem.lastname}`,
          password: elem.password,
        }))
        .sort((a, b) => (a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1));
    },
    usersFiltered() {
      if (this.search) {
        const search = this.search.toLowerCase();
        return this.usersPackage.filter((elem) => {
          if (elem.email.toLowerCase().includes(search)) {
            return true;
          }
          if (elem.name.toLowerCase().includes(search)) {
            return true;
          }
          return false;
        });
      }
      return this.usersPackage;
    },
  },
  created() {
    this.getAllUsers();
  },
  methods: {
    ...mapActions(['getAllUsers']),
  },
};
</script>
