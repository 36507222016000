<template>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    :items-per-page="10"
    sort-by="t_serv"
    sort-desc
    flat>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        :color="item.password ? 'success' : 'error'"
        @click="loginToUser(item.id)"
        :disabled="loading">
        mdi-key
      </v-icon>
      -
      <v-icon
        small
        :color="item.password ? 'success' : 'error'"
        @click="loginToUserV3(item.id)"
        :disabled="loading">
        mdi-numeric-3-box
      </v-icon>
      -
      <v-icon
        small
        :color="item.password ? 'success' : 'error'"
        @click="loginToUserV3Preprod(item.id)"
        :disabled="loading">
        mdi-beta
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import usersLoginCodeServices from '@/services/usersLoginCode.services'

export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          value: 'email',
        },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'actions',
        },
      ],
      selected: [],
    }
  },
  methods: {
    async loginToUser(idUser) {
      this.loading = true
      const { data } = await usersLoginCodeServices.getUserLoginCode(idUser)
      window.open(`https://claude.samsys.io/loginCode?code=${data.code}`, '_blank').focus()
      this.loading = false
    },
    async loginToUserV3(idUser) {
      this.loading = true
      const { data } = await usersLoginCodeServices.getUserLoginCodeV3(idUser)
      window.open(`https://my.samsys.io/loginCode?code=${data.code}`, '_blank').focus()
      this.loading = false
    },
    async loginToUserV3Preprod(idUser) {
      this.loading = true
      const { data } = await usersLoginCodeServices.getUserLoginCodeV3(idUser)
      window.open(`https://my.samsys.ovh/loginCode?code=${data.code}`, '_blank').focus()
      this.loading = false
    },
  },
}
</script>
